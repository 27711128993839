import './App.css'
import React from 'react'
import Home from './Home Component/Home'
import Header from './Header Component/Header'
import Footer from './Footer Component/Footer'
import Article from './Article Component/Article'
import { useState, useEffect } from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {db, getDocs, collection} from './firebase'


const App = () => {
  const [loading, setLoading] = useState(false)
  const sections = ['projects', 'drawingBoard', 'interestingReads']
  const [contentData, setContentData] = useState([
    [{blurb: null, articleId: null, section: null, tags: [], title: null}],
    [{blurb: null, articleId: null, section: null, tags: [], title: null}],
    [{blurb: null, articleId: null, section: null, tags: [], title: null}],
    [{blurb: null, articleId: null, section: null, tags: [], title: null}]])


function getData() {
  setLoading(true)
  const sectData = []

  sections.forEach(async (sec) => {
    let snap = await getDocs(collection(db, sec))
    let data = []
    snap.forEach(doc => data.push(doc.data()))
    sectData.push(data)

    if (sectData.length === sections.length) {
      setContentData(sectData)
    }
  })
}


useEffect(() => {
  getData()
}, [])

useEffect(()=> {
  if (contentData.length === sections.length) {
    setLoading(false)
  }
}, [contentData])



return (
  <div className='App'>
  <BrowserRouter>
  <Header/>
  <Routes>
    <Route exact path='/' element={<Home loading={loading} contentData={contentData}/>}></Route>
    <Route exact path='article:id' element={<Article/>}></Route>
  </Routes>
  <Footer/>
  </BrowserRouter>
  </div>
)

}

export default App;
