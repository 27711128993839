import React from 'react'
import './SectionChildTag.css'

function SectionChildTag({tag}) {

    return (
        <div className='SectionChildTag'>
            <p>{tag}</p>
        </div>
    )
}

export default SectionChildTag