import React from 'react'
import './Article.css'
import {useParams} from 'react-router-dom'
import {db, getDoc, doc} from '../firebase'
import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid';

const Article = () => {
    const [article, setArticle] = useState({date: null, imgSrc: ['default.png', 'default.png'], text: [], section: null, title: null, links: []})
    const [loading, setLoading] = useState(true)
    const {id} = useParams()

    async function getArticle() {
        const docRef = doc(db, 'articles', 'a'+id)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
            setArticle(docSnap.data())
            setLoading(false)
        }
    }

    useEffect(() => {
        getArticle()
    }, [])

    const articleP = []
    article.text.forEach(p => {
        articleP.push(
            <div className='Article-text' key={uuid()} dangerouslySetInnerHTML={{__html: p}}/>
        )
    })

    const images = []
    article.imgSrc.forEach((i, index) => {
        images.push(
            <img className='Article-images' src={require(`../Assets/Article Images/${i}`)} alt={index}></img>
        )
    })

    const links = []
    article.links.forEach(l => {
        links.push(
            <a href={l.link} className={'Article-links'}>{l.linkText}</a>
        )
    })



    if (loading) {
        return (
            <div className='Article'>
                <h3 className='data-fetch-msg'>Loading.. </h3> 
            </div>
        )
    } else {
        return (
            <div className={`Article Article-${article.section}`}>
                <div className='Article-container'>

                    <div className={`Article-c-1 Article-c-1-${article.section}`}>
                        <div className='Article-c-1-1'>
                            <h3 className='Article-title'>{article.title}</h3>
                            <p className='Article-section'>{article.section}</p>
                            <p className='Article-date'>{article.date}</p>
                        </div>
                    </div>

                    <div className='Article-c-2'>
                        <div className='Article-c-2-1'>
                            {articleP}
                            {links}
                        </div>
                        <div className='Article-c-2-2'>
                            {images}
                        </div>
                    </div>
                
                </div>
            </div>
            )
        }
}

export default Article