import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, collection, doc, getDoc } from "firebase/firestore";

require('firebase/firestore');


// const firebaseConfig = {
//   apiKey: "AIzaSyBgLj9p7uRHEhjbxcQRtbqsLhzU48oUwWk",
//   authDomain: "areactivewebsite.firebaseapp.com",
//   projectId: "areactivewebsite",
//   storageBucket: "areactivewebsite.appspot.com",
//   messagingSenderId: "499770130962",
//   appId: "1:499770130962:web:556d22024b21fcb0ba4ecb"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBgLj9p7uRHEhjbxcQRtbqsLhzU48oUwWk",
  authDomain: "areactivewebsite.firebaseapp.com",
  projectId: "areactivewebsite",
  storageBucket: "areactivewebsite.appspot.com",
  messagingSenderId: "499770130962",
  appId: "1:499770130962:web:556d22024b21fcb0ba4ecb"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export {db, getDocs, collection, doc, getDoc}