import React from 'react'
import './Hero.css'


function Hero() {

    return (
        <div className='Hero'>
            <div className='Hero-child-one'>
                <div className='Hero-child-one-one'>
                <h1 className='Hero-child-text'>If<span className='Hero-black'> you've made it</span> this far..</h1>
                <h1 className='Hero-child-text'>You're <span className='Hero-black'>curious</span> </h1>
                <h1 className='Hero-child-text'>and that's <span className='Hero-black'>great</span> </h1>
                </div>
                <div className='Hero-child-one-two' >
                    <img src={require('../Assets/Illustrations/study.png')} alt='ok'></img>
                </div>
            </div>


            <div className='Hero-child-three'>
                <p className='Hero-sub-text-bold'>Hi there,</p>
                <p className='Hero-sub-text'>here you'll find discussions + ideas within the finance & technology space</p>
            </div>

        </div>
    )
}

export default Hero