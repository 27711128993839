import React from 'react'
import './Header.css'

function Header() {

    return (
        <div className="Header" onClick={() => {window.location = "/"}}>
            <div className="Header-child">
                <h3 className="Header-child-logo">Tahmid Rahman | <span>Fintech</span></h3>
                <div className='Header-child-contact' onClick={() => {window.location = "mailto:Tahmid_r@icloud.com"}}><p>CONTACT ME</p></div>
            </div>
        </div>
    )
}

export default Header