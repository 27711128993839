import React from 'react'
import './Section.css'
import SectionChild from './SectionChild'
import { v4 as uuid } from 'uuid';

//where content[0] = the first project in the section 

function Section({sectionClass, content}) {
    const topics = []
    content.forEach(topic => {
        topics.push(<SectionChild sectionClass={sectionClass} content={topic} key={uuid()}></SectionChild>)
    })

    return (
        <div className='Section-container'>
            <h3 className='Section-header'>{content[0].section}<span className={sectionClass + '-span'}>.</span></h3>
            <div className={'Section'}>
                {topics}
            </div>
            </div>
    )
}

export default Section