import React from 'react'
import './SectionChild.css'
import SectionChildTag from './SectionChildTag'
import {useNavigate} from 'react-router-dom'
import { v4 as uuid } from 'uuid';

function SectionChild({sectionClass, content}) {
    const nav = useNavigate()

    const tags = []
    content.tags.forEach(tag => {
        tags.push(
            <SectionChildTag tag={tag} sectionClass={sectionClass} key={uuid()}></SectionChildTag>
        )
    })

    
    return (
        <div className={'SectionChild ' + sectionClass} onClick={() => {
            nav('article' + content.articleId)
        }}>
            <div className='SectionChild-c-1'>
            <h3 className={`SectionChild-title ${sectionClass}-title`}>{content.title}</h3>
            <p className='SectionChild-blurb'>{content.blurb}</p>
            </div>
            <div className='SectionChild-c-2 '>
                {tags}
            </div>
        </div>
    )
}

export default SectionChild