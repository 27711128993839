import React from 'react'
import './Footer.css'

function Footer() {

    return (
        <div className='Footer'>
            <h3 className='title'>Tahmid Rahman | <span>FINTECH.</span></h3>
            <ul className='list'>
                <li className='list-item'><a href='https://www.linkedin.com/in/tahmid-/'>LINKEDIN</a></li>
                <li className='list-item'><a href='https://github.com/TeeRahman'>GITHUB</a></li>
                <li className='list-item'><a href='https://www.kaggle.com/'>KAGGLE</a></li>
            </ul>
            <p className='location'> BRIGHTON, UK</p> 
        </div>
    )
}

export default Footer