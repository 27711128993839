import React from 'react'
import Hero from '../Hero Component/Hero'
import Section from '../Section Component/Section'


function Home({loading, contentData}) {
    if (loading) {
        return (
          <>
            <Hero />
            <h3 className='data-fetch-msg'>Loading.. </h3>       
          </>
        )
    } else {
        return (
        <>
            <Hero />
            <Section sectionClass={'first-section'} content={contentData[0]}/>
            <Section sectionClass={'second-section'} content={contentData[1]}/>
            <Section sectionClass={'third-section'} content={contentData[2]}/>
      </>
        )
    }
}

export default Home